export default defineNuxtPlugin({
  name: "CCM19",
  async setup() {
    const { data: jobShop } = useNuxtData("jobShopData")
    const cookieConsentCCM19ApiKey
      = jobShop.value?.externalScriptSettings?.ccm19?.apiKey

    const cookieConsentCCM19DomainKey
      = jobShop.value?.externalScriptSettings?.ccm19?.domainKey

    const script = document.createElement("script")

    // Only add CCM19 script to the document if api & domain key are provided
    if (cookieConsentCCM19DomainKey && cookieConsentCCM19ApiKey) {
      script.src = `https://consent.talentsconnect.com/ccm19/ccm19.js?apiKey=${cookieConsentCCM19ApiKey}&domain=${cookieConsentCCM19DomainKey}`
      document.head.appendChild(script)
    }

    return {
      provide: {
        openCCMWidget: () => {
          if (typeof CCM !== "undefined")
            CCM.openWidget()
        },
      },
    }
  },
})
