import revive_payload_client_7OoMXKjzO9 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Hhqt6BVew2 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8p6IOZ6DtH from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rNR79Sx4vn from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IqTXCvXGT6 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_rxRlpsNnPy from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_curaFjje1a from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_uz2xrgrkrdqv2pmwrahgimehnu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_iztX3tQUJN from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.20.0_vue@3.4.37_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import amplitude_client_a5NDjvTWdZ from "/app/plugins/amplitude.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import append_parameters_directive_KEd3bskr0F from "/app/plugins/append-parameters-directive.ts";
import ccm19_client_MmBPIpSdFj from "/app/plugins/ccm19.client.ts";
import configcat_KgI70cfSIX from "/app/plugins/configcat.ts";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import third_party_integrations_client_tQxxkJnfI1 from "/app/plugins/third-party-integrations.client.ts";
export default [
  revive_payload_client_7OoMXKjzO9,
  unhead_Hhqt6BVew2,
  router_8p6IOZ6DtH,
  payload_client_rNR79Sx4vn,
  navigation_repaint_client_IqTXCvXGT6,
  chunk_reload_client_rxRlpsNnPy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_curaFjje1a,
  i18n_iztX3tQUJN,
  amplitude_client_a5NDjvTWdZ,
  api_GFfDXud5Cr,
  append_parameters_directive_KEd3bskr0F,
  ccm19_client_MmBPIpSdFj,
  configcat_KgI70cfSIX,
  gtm_client_OzmBuHLRIb,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  third_party_integrations_client_tQxxkJnfI1
]