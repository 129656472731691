import type { IConfigCatClient } from "configcat-js-ssr"
import * as configcat from "configcat-js-ssr"
import { nanoid } from "nanoid"

export default defineNuxtPlugin({
  name: "ConfigCat",
  async setup() {
    const { public: { configCatApiKey } } = useRuntimeConfig()
    const { tenantId } = useHeaderStore()

    const logger = configcat.createConsoleLogger(configcat.LogLevel.Off)

    // Define ConfigCat default user with a random identifier and the tenantId
    const userObject = new configcat.User(
      nanoid(),
    )
    userObject.custom = {
      tenantId,
    }

    // Create ConfigCat client
    const configCatClient: IConfigCatClient = configcat.getClient(
      configCatApiKey,
      configcat.PollingMode.LazyLoad,
      {
        dataGovernance: configcat.DataGovernance.EuOnly,
        logger,
        defaultUser: userObject,
        cacheTimeToLiveSeconds: 600,
      },
    )

    return {
      provide: {
        configCat: configCatClient,
      },
    }
  },
})
